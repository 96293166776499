var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',{staticClass:"d-flex justify-between pt-0 px-0"},[_c('h4',[_vm._v(_vm._s(_vm.$t("animals-alarms")))])]),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(_vm.isLoading)?_c('b-spinner',{attrs:{"label":"Loading..."}}):_vm._e()],1),_c('div',{staticStyle:{"max-height":"320px","overflow":"auto"}},[_c('b-table',{ref:"animalAlarmsTableRef",attrs:{"items":_vm.animalsAlarms,"responsive":"","primary-key":"id","fields":[
        {
          key: 'alarmtype',
          label: _vm.$t('alarmType'),
          sortable: false,
          formatter: function (val) { return _vm.$t(val); },
        },
        {
          key: 'alarmdatetime',
          label: _vm.$t('alarmDateTime'),
          sortable: false,
          formatter: function (val) { return _vm.dayjs(val).locale(_vm.$i18n.locale).format('DD.MM.YYYY dddd'); },
        },
        {
          key: 'actions',
          label: _vm.$t('actions'),
          sortable: false,
        } ],"show-empty":"","sticky-header":"","empty-text":_vm.$t('noRecords')},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":"outline-danger","to":{
            name: 'animal-actions',
            query: {
              id: data.item.animalid,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("show"))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }