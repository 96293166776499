<template>
  <b-card>
    <b-card-header class="d-flex justify-between pt-0 px-0">
      <h4>{{ $t("animalStats") }}</h4>
      <b-link :to="{ name: 'animals' }">
        {{ $t("seeAll") }} <feather-icon icon="ChevronRightIcon" />
      </b-link>
    </b-card-header>
    <vue-apex-charts
      v-if="!loading"
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
    />
  </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardHeader, BLink } from "bootstrap-vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BLink,
  },
  data: () => {
    return {
      totalAnimal: 0,
      loading: true,
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#ffe700", "#00d4bd", "#826bf8", "#2b9bf4", "#FFA1A1"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)} adet`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Hayvanlar",
                    formatter() {
                      return "100%";
                    },
                  },
                },
              },
            },
          },
          labels: [""],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },
  async mounted() {
    var { data } = await this.$http.get("/Animals/GetAnimalMetrics", {
      headers: {
        culinfo: this.$i18n.locale,
        plantid: this.$store.state.app.selectedPlantId,
      },
    });

    this.donutChart.chartOptions.labels = data.resultdata.map(
      (x) => x.animalType
    );
    this.donutChart.chartOptions.plotOptions.pie.donut.labels.total.label =
      "Hayvanlar";

    this.donutChart.series = data.resultdata.map((x) => x.numberOfAnimals);
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
