<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title> {{ $t("companySummary") }} </b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0" />
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="success">
                <ModulesSvg height="32" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ modules.join(",") }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("modules") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- // işletme -->
        <b-col class="mt-2 mt-sm-0" xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="info">
                <PlantsSvg height="32" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.numberofplants }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("plantCount") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- // product -->
        <b-col class="mt-2 mt-xl-0" xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="warning">
                <ProductSvg height="32" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.numberofproducts }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("productCount") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <!-- // users -->
        <b-col class="mt-2 mt-xl-0" xl="3" sm="6">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="danger">
                <UsersSvg height="32" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ data.numberofusers }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("userCount") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import ModulesSvg from "@/assets/images/icons/modules.svg";
import PlantsSvg from "@/assets/images/icons/plants.svg";
import ProductSvg from "@/assets/images/icons/products.svg";
import UsersSvg from "@/assets/images/icons/users.svg";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    ModulesSvg,
    PlantsSvg,
    ProductSvg,
    UsersSvg,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      modules: [],
    };
  },
  mounted() {
    if (this.data.hasdairy) this.modules.push(`${this.$t("milk")}`);
    if (this.data.hasbeef) this.modules.push(`${this.$t("beef")}`);
    if (this.data.hassheep) this.modules.push(`${this.$t("sheep")}`);
    if (this.data.hasgoat) this.modules.push(`${this.$t("goat")}`);
    if (this.data.hasmix) this.modules.push(`${this.$t("mix")}`);
  },
};
</script>
