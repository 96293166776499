<template>
  <b-card>
    <b-card-header class="d-flex justify-between pt-0 px-0">
      <h4>{{ $t("animals-alarms") }}</h4>
    </b-card-header>
    <div class="d-flex justify-content-center align-items-center">
      <b-spinner v-if="isLoading" label="Loading..."></b-spinner>
    </div>
    <div style="max-height: 320px; overflow: auto">
      <b-table
        ref="animalAlarmsTableRef"
        :items="animalsAlarms"
        responsive
        primary-key="id"
        :fields="[
          {
            key: 'alarmtype',
            label: $t('alarmType'),
            sortable: false,
            formatter: (val) => $t(val),
          },
          {
            key: 'alarmdatetime',
            label: $t('alarmDateTime'),
            sortable: false,
            formatter: (val) =>
              dayjs(val).locale($i18n.locale).format('DD.MM.YYYY dddd'),
          },
          {
            key: 'actions',
            label: $t('actions'),
            sortable: false,
          },
        ]"
        show-empty
        sticky-header
        :empty-text="$t('noRecords')"
      >
        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="outline-danger"
            :to="{
              name: 'animal-actions',
              query: {
                id: data.item.animalid,
              },
            }"
          >
            {{ $t("show") }}
          </b-button>
        </template>
      </b-table>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BSpinner, BTable, BButton } from "bootstrap-vue";
import { mapState } from "vuex";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
export default {
  components: {
    BCard,
    BCardHeader,
    BSpinner,
    BTable,
    BButton,
  },
  data: () => {
    return {
      dayjs,
    };
  },

  computed: {
    ...mapState({
      animalsAlarms: (state) => state.animalsModule.animalsAlarms || {},
      isLoading: (state) => state.animalsModule.loading,
    }),
  },
  mounted() {
    this.$store.dispatch("animalsModule/fetchAnimalsAlarms");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
