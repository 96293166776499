<template>
  <div>
    <b-row v-if="isLoggedIn">
      <b-col cols="12" sm="3" md="3" class="h-100">
        <company-logo :data="companyData" />
      </b-col>
      <b-col cols="12" sm="9" class="h-100">
        <company-info :data="companyData" />
      </b-col>
    </b-row>
    <b-row>
      <b-col ref="statRef" cols="12" lg="6">
        <animal-stats />
      </b-col>
      <!-- max height equal to statRef -->
      <b-col cols="12" lg="6">
        <animal-alarms />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <user-view-user-timeline-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CompanyInfo from "./Dashboard/CompanyInfo.vue";
import CompanyLogo from "./Dashboard/CompanyLogo.vue";
import UserViewUserTimelineCard from "./company/Users/UserView/UserViewUserTimelineCard.vue";
import { getUserData } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import { initialAbility } from "@/libs/acl/config";
import AnimalStats from "./charts/AnimalStats.vue";
import AnimalAlarms from "./Animals/components/AnimalAlarms.vue";
export default {
  components: {
    BRow,
    BCol,
    CompanyInfo,
    CompanyLogo,
    UserViewUserTimelineCard,
    AnimalStats,
    AnimalAlarms,
  },
  data: function () {
    return {
      companyData: getUserData(),
      isLoggedIn: true,
    };
  },

  mounted() {
    if (getUserData() == null) {
      this.logout();
      this.isLoggedIn = false;
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style></style>
