<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title> {{ $t("companySummary") }} </b-card-title>
    </b-card-header>
    <b-row class="px-2 pb-1">
      <b-col>
        <b-img
          :src="
            'https://api.herdoncloud.com/Company_Pictures/' +
            data.companypictureurl
          "
          style="height: 100px"
          alt="Medal Pic"
          class="mx-auto"
        />
      </b-col>
      <b-col>
        <div class="mt-1">
          <h5>{{ data.name }}</h5>
          <span class="font-small-3">{{ data.companyname }} </span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BCard,
    BImg,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
};
</script>
